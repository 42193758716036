import { createContext } from "react";

export interface IUserContext {
  email: string;
  role: string;
  token: string;
  type: "INTERNAL" | "EXTERNAL";
  id: number;
  impersonate: boolean;
}

export const UserContext = createContext<{
  value: IUserContext | null;
  set: ((a: IUserContext) => void);
}>({ value: null, set: () => {} });
