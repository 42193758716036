import { lazy, Suspense, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Header, { ProactiveFooter } from "./Components/ProactiveHeader";
import HelixLoader from "./Utils/HelixLoader";
import { getQueryVar } from "./Utils/getQueryVar";
import PageTracker from "./Utils/Tracking/PageTracker";
import styled from "styled-components";

import { UserContext } from "./Components/UserContext";

const Home = lazy(() => import("./Pages/Home"));
const Run = lazy(() => import("./Pages/Run"));
const Instrument = lazy(() => import("./Pages/Instrument"));
const Account = lazy(() => import("./Pages/Account"));
const CustomQuery = lazy(() => import("./Pages/CustomQuery"));
const AlertCMS = lazy(() => import("./Pages/AlertCMS"));
const LogParsing = lazy(() => import("./Pages/LogParsing"));
const UserSpoofing = lazy(() => import("./Pages/UserSpoofing"));
const Unauthorized = lazy(() => import("./Pages/401Page"));

const MasterStyles = styled.div`
  .hide {
    display: none !important;
  }
`;
const PageWrapper = styled.div`
  min-height: 100vh;
`;

const App = () => {
  const role = useContext(UserContext).value?.role || "";
  const isImpersonate = useContext(UserContext).value?.impersonate || "";
  return (
    <MasterStyles className="App">
      <BrowserRouter>
        <Header />
        <PageWrapper>
          <Switch>
            <Route path="/run/ipsRunId/:ipsRunId">
              <Suspense fallback={<HelixLoader />}>
                <Run />
              </Suspense>
            </Route>
            <Route path="/run/runId/:runId/env/:env">
              <Suspense fallback={<HelixLoader />}>
                <Run />
              </Suspense>
            </Route>
            <Route path="/run">
              {getQueryVar("ipsRunId") ? (
                <Redirect to={`/run/ipsRunId/${getQueryVar("ipsRunId")}`} />
              ) : (
                <Redirect
                  to={`/run/runId/${getQueryVar("runid")}/env/${getQueryVar(
                    "env"
                  )}`}
                />
              )}
            </Route>

            <Route path="/instrument/:instrumentId">
              <Suspense fallback={<HelixLoader />}>
                <Instrument />
              </Suspense>
            </Route>

            <Route path="/account/:accountId">
              <Suspense fallback={<HelixLoader />}>
                <Account />
              </Suspense>
            </Route>

            <Route path="/custom-query">
              <Suspense fallback={<HelixLoader />}>
                {role !== "CP" ? (
                  <Suspense fallback={<HelixLoader />}>
                    <CustomQuery />
                  </Suspense>
                ) : (
                  <Redirect to="/unauthorized" />
                )}
              </Suspense>
            </Route>

            <Route path="/alert-cms">
              {role === "ADMIN" ? (
                <Suspense fallback={<HelixLoader />}>
                  <AlertCMS />
                </Suspense>
              ) : (
                <Redirect to="/" />
              )}
            </Route>

            <Route path="/log-parsing/instrument/:instrumentId/ipsRunId/:ipsRunId">
              <Suspense fallback={<HelixLoader />}>
                {role !== "CP" ? (
                  <Suspense fallback={<HelixLoader />}>
                    <LogParsing />
                  </Suspense>
                ) : (
                  <Redirect to="/unauthorized" />
                )}
              </Suspense>
            </Route>

            <Route path="/user-spoofing">
              <Suspense fallback={<HelixLoader />}>
                {(role === "ADMIN" || isImpersonate === true) ? (
                  <Suspense fallback={<HelixLoader />}>
                    <UserSpoofing />
                  </Suspense>
                ) : (
                  <Redirect to="/unauthorized" />
                )}
              </Suspense>
            </Route>

            <Route path="/unauthorized">
              <Suspense fallback={<HelixLoader />}>
                <Unauthorized />
              </Suspense>
            </Route>

            <Route path="/">
              <Suspense fallback={<HelixLoader />}>
                {role !== "CP" ? (
                  <Suspense fallback={<HelixLoader />}>
                    <Home />
                  </Suspense>
                ) : (
                  <Redirect to="/unauthorized" />
                )}
              </Suspense>
            </Route>
          </Switch>
        </PageWrapper>
        <PageTracker />
      </BrowserRouter>
      <ProactiveFooter />
    </MasterStyles>
  );
};

export default App;
