import { downloadFileType } from "../../Components/DownloadModal";

export const categoryNames = {
  my_cases: "my_cases",
  my_instruments: "my_instruments",
  my_accounts: "my_accounts",
  my_account_banner: "my_account_banner",
  search: "search",
  account_page: "account_page",
  instrument_page: "instrument_page",
  run_page: "run_page",
};

export const actionNames = {
  load_complete: "load_complete",
  click: "click",
  table_filter: "table_filter",
  expand: "expand",
  download: "download",
  chart_filter: "chart_filter",
  copy: "copy",
};

export const convertToString = (
  val: string | string[] | boolean | number
): string => {
  if (typeof val === "boolean") {
    return val ? "true" : "false";
  }

  if (typeof val === "number") {
    return val.toString();
  }

  if (Array.isArray(val) && typeof val[0] === "string") {
    return val.join(",");
  }

  return val as string;
};

export const setUserID = (email: string, role: string) => {
  try {
    if ("gtag" in window) {
      window.gtag("config", "G-K32FLBVHLV", {
        user_id: email,
      });

      //@ts-ignore
      window.gtag("set", "user_properties", {
        role,
        username: email.split("@")[0],
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export interface IEventTrack {
  category: string;
  action: string;
  label?: string | string[] | number | boolean;
  value?: number;
  data?: string;
  image?: string;
  log?: string;
  sav?: string;
}

export interface GtagParams extends Gtag.EventParams {
  data?: string;
  image?: string;
  log?: string;
  sav?: string;
}

const eventTrack = ({ category, action, label, value, data, image, log, sav}: IEventTrack) => {
  if ("gtag" in window) {
    const body: GtagParams = {
      event_category: category,
    };

    if (typeof label !== "undefined") {
      body.event_label = convertToString(label);
    }

    if (typeof value === "number") {
      body.value = value;
    }

    if (typeof data === "string") {
      body.data = data;
    }

    if (typeof image === "string") {
      body.image = image;
    }

    if (typeof log === "string") {
      body.log = log;
    }

    if (typeof sav === "string") {
      body.sav = sav;
    }

    window.gtag("event", action, body);
  }
};

export default eventTrack;
