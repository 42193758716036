import { Space, Row, Col, Typography } from "antd";
import { FileExcelOutlined, FormOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { UserContext } from "../../Components/UserContext";

const { Text } = Typography;

const AppDrawerFormat = styled(Row)`
  & {
    min-width: 400px;
  }
  .anticon {
    font-size: 30px;
    display: inline-block;
  }
  .app {
    text-align: center;
  }
  padding: 20px 0;
`;

const IconLink = styled(Link)`
  margin: 1rem;
  display: inline-block;
`;

const AppDrawer = (pr: { control: (a: boolean) => void }) => {
  const role = useContext(UserContext).value?.role || "";
  const isImpersonate = useContext(UserContext).value?.impersonate || "";
  return (
    <AppDrawerFormat>
      <Col span={6} className="app">
        <Link to="/custom-query" onClick={() => pr.control(false)}>
          <Space direction="vertical" align="center">
            <FileExcelOutlined />
            <Text>Custom Query</Text>
          </Space>
        </Link>
      </Col>
      {role === "ADMIN" && (
        <Col span={6} className="app">
          <Link to="/alert-cms" onClick={() => pr.control(false)}>
            <Space direction="vertical" align="center">
              <FormOutlined />
              <Text>Alert CMS</Text>
            </Space>
          </Link>
        </Col>
      )}
      {(role === "ADMIN" || isImpersonate === true) && (
        <Col span={6} className="app">
          <Link to="/user-spoofing" onClick={() => pr.control(false)}>
            <Space direction="vertical" align="center">
              <UserSwitchOutlined />
              <Text>User Spoofing</Text>
            </Space>
          </Link>
        </Col>
      )}
    </AppDrawerFormat>
  );
};

export default AppDrawer;
